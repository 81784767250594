import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { H1, H2, Paragraph, ContentContainer, UnorderedList, ListItem, ExternalLink, InternalLink } from "../components/ds"

const Page = () => {
  const data = useStaticQuery(graphql`
    query {
      flowchart: file(relativePath: { eq: "content/klagomalshantering.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO title="Synpunkter/tankar om verksamheten" />
      <Layout>
        <ContentContainer>
          <H1>Synpunkter/tankar om verksamheten</H1>
          <Paragraph>Har du synpunkter, klagomål eller beröm? Dina synpunkter är värdefulla för att vi ska kunna förbättra verksamheten.</Paragraph>
          <H2>Gör så här</H2>
          <Paragraph>Vänd dig i första hand till den personal som är berörd. Om du inte blir nöjd kan du prata med förskolechefen. Känner du dig fortfarande inte nöjd så kan du kontakta styrelsen och göra en klagomålshantering.</Paragraph>
          <Paragraph>Inkommer klagomål till <InternalLink to="/styrelsen">styrelsen</InternalLink> dokumenterar vi denna i våra protokoll som förs under styrelsemötena. Du kan lämna klagomål anonymt, men då kan vi inte ge dig återkoppling. Efter det att ditt klagomål har behandlats av styrelsen får du en bekräftelse på att vi har mottagit det.</Paragraph>
          <Paragraph>Vi utreder klagomålet med berörda personer och styrelsen kommer att kontakta dig via e-post eller telefon. För att kunna skicka svaret måste vi ha dina kontaktuppgifter. Om du vill ha svar via e-post räcker det med din e-postadress. Dina personuppgifter behandlas i enlighet med personuppgiftslagen.</Paragraph>
          <Paragraph>Enligt Skollagen 2010:800 kap 4 §8, ska huvudmannen ha rutiner för klagomålshantering. På Förskolan Brandbilen har styrelsen beslutat att klagomål skall lämnas till styrelsen för förskolan.</Paragraph>
          <Paragraph>Om du pratat med förskolan och ändå inte fått hjälp kan du göra en anmälan till skolinspektionen, BEO eller Diskrimineringsombudsmannen.</Paragraph>
          <Paragraph>Tillsammans med Skolinspektionen övervakar BEO den del av skollagen (6 kapitlet) som gäller kränkande behandling. BEO utreder bara kränkande behandling som riktats mot barn och elever.</Paragraph>
          <Paragraph>Det råder nolltolerans mot kränkningar i förskolan och skolan. Alla barn och elever har rätt att vistas i en skolmiljö som är fri från diskriminering, trakasserier och kränkande behandling. Arbetet mot kränkande behandling är en viktig del av att tillgodose elevers och barns rätt till god utbildning i en trygg miljö.</Paragraph>
          <Paragraph>Till Diskrimineringsombudsmannen kan du vända dig om du själv eller någon annan missgynnats eller kränkts på grund av kön, könsidentitet eller könsuttryck, etnisk tillhörighet, religion, funktionsnedsättning, sexuell läggning eller ålder? Då kan det handla om diskriminering. Det kan du anmäla till DO.</Paragraph>
          <Paragraph>Du kan skicka ditt klagomål/synpunkter till: <ExternalLink href="mailto:styrelsen@forskolanbrandbilen.se" target="_top">styrelsen@forskolanbrandbilen.se</ExternalLink></Paragraph>
          <Paragraph>Vill du göra anmälan anonymt går det bra att hämta denna blankett i förskolans postlåda som sitter inne i förskolans hall. Lägg sedan blanketten i facket för Ordförande i hallen.</Paragraph>
          <UnorderedList>
            <ListItem><ExternalLink href="http://www.do.se/att-anmala">Diskrimineringsombudsmannen</ExternalLink></ListItem>
            <ListItem><ExternalLink href="https://beo.skolinspektionen.se/sv/Om-BEO/">Barn- och elevombudet BEO</ExternalLink></ListItem>
            <ListItem><ExternalLink href="https://elev.skolinspektionen.se/sv">Skolinspektionen</ExternalLink></ListItem>
          </UnorderedList>
          <Img fluid={data.flowchart.childImageSharp.fluid} className="w-full h-full" />
        </ContentContainer>
      </Layout>
    </>
  )
}



export default Page
